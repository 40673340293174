


<template>
    <div class="container mt-4 mx-1250">
        <div class="row align-items-center mb-3">
            <div class="title-branch">
                <div class="panl-title" style="    padding-right: 9px;">
                    <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" />
                    <router-link :to="'/media?lang='+$i18n.locale">
                        <div class="panl-title-text d-inline tl"> {{ $t("index_videos") }}</div>
                    </router-link>
                </div>
                <hr>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8">
                <h5 class="border-left mb-3"><a href="#" class="tl">{{ $t('photos_sort_by_newst') }}</a></h5>
                <div class="video-container mb-4" >
                    <div class="video-title">
                        <h3>{{ mainVideo.title }}</h3>
                    </div>
                    <router-link :to="'/media/'+videos[0].request+'?lang='+$i18n.locale">
                        <img v-if="videos[0].photo" :src="'https://alkafeel.net/videos/mcroped95/500/'+videos[0].photo" class="img-fluid" alt="Video Thumbnail" style="width: 100%; max-height: 450px; cursor: pointer;">
                        <div class="video-icon">
                            <img src="assets/img/nplay.png" alt="">
                        </div>
                    </router-link>
                    
                    
                    <!-- <video v-if="videosPlaying[0]" ref="videoPlayer"  controls class="vidoe-h">
                        <source :src="mainVideo.video_src" type="video/mp4">
                    </video> -->
                </div>
            </div>


            <div class="col-md-4">
                <h5 class="border-left mb-3"><a href="#" class="tl">{{ $t('short_videos') }}</a></h5>

                <div class="video-container mb-4">
                    <div class="video-title">
                        <h3>{{ shortVideos.title }}</h3>
                    </div>
                    <router-link :to="'/media/'+shortVideos.request+'?lang='+$i18n.locale">
                        <img v-if="shortVideos.photo" :src="'https://alkafeel.net/videos/mcroped11/290/'+shortVideos.photo" class="img-fluid" alt="Video Thumbnail" style="width: 100%; height: 450px; cursor: pointer; object-fit: cover;">
                        <div class="video-icon">
                            <img src="assets/img/nplay.png" alt="">
                        </div>
                    </router-link>
                    
                    
                    <!-- <video v-if="videosPlaying[1]" ref="videoPlayer" style="width: 100%; height: 450px;" controls>
                        <source :src="shortVideos.video_src" type="video/mp4">
                    </video> -->
                </div>
            </div>

        </div>
        
        <div class="row g-3 mt-4">
            <div class="col-12 col-lg-3" v-for="(video, index) in videos.slice(1,5)" :key="index">
                <router-link :to="'/media/'+video.request+'?lang='+$i18n.locale">
                    <div class="video-thumbnail">
                        <div class="video-timer">
                            <img v-if="video.photo" :src="'https://alkafeel.net/videos/mcroped11/290/'+video.photo" class="img-fluid cu-mo" alt="Thumbnail" style="width: 100%; height: 150px;object-fit: cover;">
                            <span class="video-time" v-if="video.length" v-html="formatLength(video.length)"></span>
                        </div>
                        <div>
                            <!-- <h6 class="tl">{{ video.category }}</h6> -->
                            <p class="tl">{{ video.title.substring(0, 65) }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                videosPlaying: [false, false],
                res: [],
                mainVideo: {
                    thumbnail: 'https://images.unsplash.com/photo-1539584855745-e282984ced68?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                    title: 'محاضرة دينية بذكرى استشهاد فاطمة الزهراء (عليها السلام) يرتقي المنبر السيد منتظر الحيدري في صحن العتبة العباسية المقدسة',
                    date: '03/12/2024',
                    videoSrc: 'your-video-file.mp4'
                },
                shortVideos: [],
                videos: [{
                        thumbnail: 'https://images.unsplash.com/photo-1629206755907-d0e179c07dd6?q=80&w=1996&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                        duration: '5:27',
                        category: 'المحاضرات الدينية',
                        title: 'العتبة العباسية تختتم فعاليات المهرجان النسوي الثاني في باكستان',
                    },
                    {
                        thumbnail: 'https://images.unsplash.com/photo-1598599759072-652b37d45d45?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                        duration: '9:22',
                        category: 'المحاضرات الدينية',
                        title: 'مواكب العزاء تفد على مرقد أبي الفضل العباس لتعزيته بذكرى وفاة السيدة أمّ البنين ',
                    },
                    {
                        thumbnail: 'https://images.unsplash.com/photo-1598015415986-694e0644bdc5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                        duration: '24:09',
                        category: 'المحاضرات الدينية',
                        title: 'وفد العتبة العبّاسية يُجري زيارةً لمدارس الهادي (عليه السلام) في باكستان',
                    },
                    {
                        thumbnail: 'https://images.unsplash.com/photo-1599676588319-b8770abd8a64?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                        duration: '4:14',
                        category: 'المحاضرات الدينية',
                        title: 'جامعة الكفيل تختتم الدورة التدريبية الثالثة لنظام مسار بولونيا الرقمي',
                    },
                ],
            };
        },
        mounted() {
            this.ini();
        },
        methods: {
            playVideo(index) {
                this.videosPlaying = this.videosPlaying.map((_, i) => i === index); 
                this.$nextTick(() => {
                    if (this.$refs.videoPlayer[index]) {
                        this.$refs.videoPlayer[index].play();
                    }
                });
            },
            ini() {
                this.loading = true;
                this.$axios.get("videos/home/5", {})
                .then(res => {
                    this.loading = false;
                    this.videos = res.data.data;
                    this.mainVideo = this.videos[0];
                });

                this.$axios.get("videos/short/1", {})
                .then(res => {
                    this.shortVideos = res.data.data[0];
                });
            },
            formatLength (i) {
                var Length = i.split(".");
                if (Length[0].substring(0,3) == '00:') {return Length[0].substring(3);}
                else {return Length[0];}
            },
        }
    };
</script>


<style scoped>
    .title-branch {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;

    }

    .branch {
        display: flex;
    }

    .branch>h6 {
        font-size: 16px;
        margin: 0 8px;
        font-weight: lighter;
    }

    .branch>h6:hover {
        font-weight: bolder;
    }

    .branch>h6>a {
        text-decoration: none;
        color: #333333;
    }

    .mx-1250 {
        max-width: 1250px;
    }

    .video-container {
        position: relative;
        text-align: center;
    }

    .video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2rem;
        color: white;
    }

    .video-thumbnail {
        position: relative;
    }

    .video-time {
        position: absolute;
        bottom: 10px;
        right: 5px;
        background: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 2px 5px;
        border-radius: 3px;
    }

    .video-thumbnail p {
        font-weight: 600;
    }

    .video-thumbnail h6 {
        font-weight: lighter;
        margin: 2px 0;
    }

    .video-icon img {
        cursor: pointer;
    }

    a {
        text-decoration: none;
        color: #333333;
    }

    .border-left {
        border-left: 3px solid #B1BD52;
        padding: 0 8px;
    }

    html[lang="ar"] .border-left{
        border-right: 3px solid #B1BD52;
        border-left: none;
}

html[lang="pr"] .border-left{
        border-right: 3px solid #B1BD52;
        border-left: none;
}

html[lang="ur"] .border-left{
        border-right: 3px solid #B1BD52;
        border-left: none;
}

    .social {
        display: flex;
        align-items: center;
        margin-top: 15px;
    }

    .social a {
        margin-right: 10px;
    }

    .border-right {
        border-right: 1px solid #ccc;
        padding: 0 6px;
    }

    @media (min-width: 320px) and (max-width: 1263px) {
        .title-branch {
            flex-direction: column;
            align-items: start;
        }

        .panl-title {
            margin-bottom: 8px;
        }

        .margin-m-0 {
            margin-top: 0 !important;
        }

        .cu-mo {
            width: 150px !important;
            height: 100px !important;
            object-fit: cover;
            margin: 0 8px;
        }

        .video-time {
            right: 12px !important;
            bottom: 12px !important;
        }

        .video-thumbnail {
            display: flex !important;
        }

        .vidoe-h {
            height: auto !important;
        }

    }

    .video-title {
        position: absolute;
        bottom: 6px;
        width:100%;
        background: linear-gradient(0deg, rgb(0 0 0 / 76%) 36.18%, rgb(77 77 77 / 3%) 76.47%);
        color: white;
        padding: 40px 16px 16px 16px;
        z-index: 10;
        text-align: right;
        cursor: pointer;
    }

    .vidoe-h {
        width: 100%;
        height: 450px;
    }


    .video-timer {
        position: relative;
    }
</style>

